




































































































































































import Vue from 'vue';

import RCP from '@/api/RCP';

import DatePicker from '@/components/DatePicker.vue';
import { ErrorResponse } from '@/types/Errors';

export default Vue.extend({
    name: 'Postback',
    data() {
        const today = new Date();
        let year = today.getFullYear();
        const monthCalc = today.getMonth() + 1;
        let month = ('0' + (monthCalc)).slice(-2);
        const day = '01';
        let secondYear = year;
        let secondMonth = ('0' + (monthCalc)).slice(-2);
        const secondDay = new Date(year, monthCalc, 0).getDate()

        if (month === '12') {
            secondYear += 1;
            secondMonth = '01';
        }

        if (month === '00') {
            year -= 1;
            month = '12';
        }

        let from = `${year}-${month}-${day}`;
        let to = `${secondYear}-${secondMonth}-${day}`;

        const first = {
            from,
            to,
            project_id: 0
        };

        from = to;

        if (secondMonth === '12') {
            secondYear += 1;
            secondMonth = '01';
        } else {
            secondMonth = ('0' + (monthCalc)).slice(-2);
        }

        to = `${secondYear}-${secondMonth}-${secondDay}`;

        const second = {
            from,
            to,
            project_id: 0
        };

        return {
            form: {
                first,
                second
            },
            actualDates: {
                past: {
                    from: first.from,
                    to: first.to
                },
                present: {
                    from: second.from,
                    to: second.to
                }
            },
            result: {
                first: [],
                second: []
            },
            errors: {
                first: '',
                second: ''
            },
            columns: ['partner_title', 'sended'],
            projects: [] as any,
            request: {
                first: false,
                second: false
            }
        };
    },
    mounted() {
        this.getFilters().then(this.getResults);
    },
    methods: {
        getLink(num: string, partner: any): string {
            return `/report?params={"reportName":"PostBack","from":"${this.form[num].from}","to":"${this.form[num].to}","partners":[${partner.partner_id}]}`;
        },
        getFilters() {
            return new Promise((resolve) => {

                this.projects = this.$store.state.filters.projects;

                if (this.projects.length > 0) {
                    this.form.first.project_id = this.projects[0].id;
                    this.form.second.project_id = this.projects[0].id;
                }

                resolve();
            });
        },
        getResults(first: any = true, second: any = true) {
            if (first && !this.request.first) {
                this.request.first = true;
                this.result.first = [];
                this.errors.first = '';

                RCP({
                    method: 'PostBack.list',
                    params: {
                        project_id: this.form.first.project_id,
                        from: this.form.first.from,
                        to: this.form.first.to
                    },
                    id: 'getResultsFirst'
                }).then((response: any) => {
                    this.result.first = response;

                    this.request.first = false;
                }).catch((error: ErrorResponse) => {
                    this.errors.first = error.message;

                    this.request.first = false;
                });
            }

            if (second && !this.request.second) {
                this.request.second = true;
                this.result.second = [];

                this.errors.second = '';

                RCP({
                    method: 'PostBack.list',
                    params: {
                        project_id: this.form.second.project_id,
                        from: this.form.second.from,
                        to: this.form.second.to
                    },
                    id: 'getResultsSecond'
                }).then((response: any) => {
                    this.result.second = response;

                    this.request.second = false;
                }).catch((error: ErrorResponse) => {
                    this.errors.second = error.message;

                    this.request.second = false;
                });
            }
        },
        whatIsPeriod(from: any, to: any): string {
            if (from === this.actualDates.past.from && to === this.actualDates.past.to) {
                return 'Прошлый месяц';
            } else if (from === this.actualDates.present.from && to === this.actualDates.present.to) {
                return 'Текущий месяц';
            } else {
                return 'Период';
            }
        }
    },
    watch: {
        'form.first': {
            handler(val) {
                if (val.from && val.to) {
                    this.getResults(true, false);
                }
            },
            deep: true
        },
        'form.second': {
            handler(val) {
                if (val.from && val.to) {
                    this.getResults(false, true);
                }
            },
            deep: true
        }
    },
    components: {
        DatePicker
    }
});
